import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext';
import CompanyFrontPage from './CompanyFrontPage';
import AdminDashboard from './AdminDashboard/AdminDashboard'
import Login from './AdminDashboard/cutsomerUtils/Login';
import Register from './AdminDashboard/cutsomerUtils/Register';
import PasswordReset from './AdminDashboard/cutsomerUtils/PasswordReset'; 

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CompanyFrontPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          } 
        />
        <Route path="/__/auth/action" element={<PasswordReset />} /> {/* Add this line */}
      </Routes>
    </Router>
  );
}

export default App;