import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createCustomer, sendPasswordReset, selectAllCustomers, fetchCustomers, editCustomer, deleteCustomer } from '../../redux/adminSlice';

export const useCustomerUtils = () => {
  const dispatch = useDispatch();
  const customers = useSelector(selectAllCustomers);

  const handleAddCustomer = async (newCustomer) => {
     dispatch(createCustomer(newCustomer));
  };

  const handleEditCustomer = async (customerId, updatedData) => {
     dispatch(editCustomer({ customerId, updatedData }));
  };

  const handleDeleteCustomer = async (customerId) => {
     dispatch(deleteCustomer(customerId));
  };

  const handleSendPasswordReset = async (email) => {
     dispatch(sendPasswordReset(email));
  };

  const fetchCustomersData = () => {
    dispatch(fetchCustomers());
  };

  return {
    customers,
    handleAddCustomer,
    handleEditCustomer,
    handleDeleteCustomer,
    handleSendPasswordReset,
    fetchCustomersData,
  };
};

export const CustomerTable = ({ customers, onSendPasswordReset, onEditCustomer, onDeleteCustomer }) => {
    const [editingCustomer, setEditingCustomer] = useState(null);
  

  const handleEditClick = (customer) => {
    setEditingCustomer({ ...customer });
  };

  const handleCancelEdit = () => {
    setEditingCustomer(null);
  };

  const handleSaveEdit = () => {
    onEditCustomer(editingCustomer.id, {
      name: editingCustomer.name,
      email: editingCustomer.email,
    });
    setEditingCustomer(null);
  };


  const handleDeleteClick = (customerId, customerName) => {
    if (window.confirm(`Are you sure you want to delete the customer "${customerName}"?`)) {
      onDeleteCustomer(customerId);
    }
  };

  const handlePasswordResetClick = (email) => {
    if (window.confirm(`Are you sure you want to send a password reset email to ${email}?`)) {
      onSendPasswordReset(email);
    }
  };

  const customersArray = Array.isArray(customers) ? customers : customers?.customers || [];

  return customersArray.length > 0 ? (
    <table className="w-full text-white">
      <thead>
        <tr>
          <th className="text-left p-2">Name</th>
          <th className="text-left p-2">Email</th>
          <th className="text-left p-2">Actions</th>
        </tr>
      </thead>
      <tbody>
        {customersArray.map((customer) => (
          <tr key={customer.id} className="border-t border-gray-600">
            <td className="p-2">
              {editingCustomer && editingCustomer.id === customer.id ? (
                <input
                  type="text"
                  value={editingCustomer.name}
                  onChange={(e) => setEditingCustomer({ ...editingCustomer, name: e.target.value })}
                  className="bg-gray-700 text-white p-1 rounded"
                />
              ) : (
                customer.name
              )}
            </td>
            <td className="p-2">
              {editingCustomer && editingCustomer.id === customer.id ? (
                <input
                  type="email"
                  value={editingCustomer.email}
                  onChange={(e) => setEditingCustomer({ ...editingCustomer, email: e.target.value })}
                  className="bg-gray-700 text-white p-1 rounded"
                />
              ) : (
                customer.email
              )}
            </td>
            <td className="p-2 space-x-2">
              {editingCustomer && editingCustomer.id === customer.id ? (
                <>
                  <button 
                    onClick={handleSaveEdit}
                    className="bg-green-500 hover:bg-green-600 text-white p-2 rounded text-sm"
                  >
                    Save
                  </button>
                  <button 
                    onClick={handleCancelEdit}
                    className="bg-gray-500 hover:bg-gray-600 text-white p-2 rounded text-sm"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button 
                    onClick={() => handleEditClick(customer)}
                    className="bg-yellow-500 hover:bg-yellow-600 text-white p-2 rounded text-sm"
                  >
                    Edit
                  </button>
                  <button 
                    onClick={() => handleDeleteClick(customer.id, customer.name)}
                    className="bg-red-500 hover:bg-red-600 text-white p-2 rounded text-sm"
                  >
                    Delete
                  </button>
                  <button 
                    onClick={() => handlePasswordResetClick(customer.email)}
                    className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded text-sm"
                  >
                    Reset Password
                  </button>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p className="text-white">No customers found. Add a customer to get started.</p>
  );
};