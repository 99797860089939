// adminSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config"; // Adjust this import based on your Firebase setup

// Async Thunks
export const createUser = createAsyncThunk(
  "admin/createUser",
  async (userData, thunkAPI) => {
    try {
      const createUserFunction = httpsCallable(
        functions,
        "adminDashboardF-createUser"
      );
      const result = await createUserFunction(userData);
      if (result.data.success) {
        alert(
          `User created successfully. Verification email sent to ${userData.email}`
        );
        return result.data;
      }
    } catch (err) {
      console.error("Error adding user: ", err);
      return thunkAPI.rejectWithValue("Failed to add user. Please try again.");
    }
  }
);

export const createCustomer = createAsyncThunk(
  "admin/createCustomer",
  async (customerData, thunkAPI) => {
    try {
      const createCustomerFunction = httpsCallable(
        functions,
        "adminDashboardF-createCustomer"
      );
      const result = await createCustomerFunction(customerData);
      if (result.data.success) {
        alert(
          `Customer ${customerData.name} created successfully. Verification email sent.`
        );
        return customerData;
      }
    } catch (err) {
      console.error("Error adding customer: ", err);
      return thunkAPI.rejectWithValue(
        "Failed to add customer. Please try again."
      );
    }
  }
);

export const sendPasswordReset = createAsyncThunk(
  "admin/sendPasswordReset",
  async (email, thunkAPI) => {
    try {
      const sendPasswordResetFunction = httpsCallable(
        functions,
        "adminDashboardF-sendPasswordResetEmail"
      );
      const result = await sendPasswordResetFunction({ customerEmail: email });
      if (result.data.success) {
        alert(`Password reset email sent to ${email}`);
        return email;
      }
    } catch (err) {
      console.error("Error sending password reset email: ", err);
      return thunkAPI.rejectWithValue(
        "Failed to send password reset email. Please try again."
      );
    }
  }
);

export const editCustomer = createAsyncThunk(
  "admin/editCustomer",
  async ({ customerId, updatedData }, thunkAPI) => {
    try {
      const editCustomerFunction = httpsCallable(functions, "editCustomer");
      const response = await editCustomerFunction({ customerId, updatedData });
      if (response.data.success) {
        return { customerId, updatedData };
      }
    } catch (error) {
      console.error("Error editing customer: ", error);
      return thunkAPI.rejectWithValue(
        "Failed to edit customer. Please try again."
      );
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "admin/deleteCustomer",
  async (customerId, thunkAPI) => {
    try {
      const deleteCustomerFunction = httpsCallable(functions, "deleteCustomer");
      const response = await deleteCustomerFunction({ customerId });
      if (response.data.success) {
        return customerId;
      }
    } catch (error) {
      console.error("Error deleting customer: ", error);
      return thunkAPI.rejectWithValue(
        "Failed to delete customer. Please try again."
      );
    }
  }
);

export const fetchCustomers = createAsyncThunk(
    'admin/fetchCustomers',
    async (_, thunkAPI) => {
      try {
        const state = thunkAPI.getState();
        const lastFetchTimestamp = state.admin.lastFetchTimestamp;
  
        const fetchCustomersFunction = httpsCallable(functions, 'adminDashboardF-fetchCustomers');
        const response = await fetchCustomersFunction({ lastFetchTimestamp });
        return { 
          customers: response.data.customers, 
          lastUpdated: response.data.lastUpdated 
        };
      } catch (error) {
        console.error("Error fetching customers: ", error);
        return thunkAPI.rejectWithValue("Failed to fetch customers. Please try again.");
      }
    }
  );
  
  export const resetCustomerPassword = createAsyncThunk(
    "admin/resetCustomerPassword",
    async ({ oobCode, newPassword }, thunkAPI) => {
      try {
        const resetPasswordFunction = httpsCallable(
          functions,
          "adminDashboardF-resetCustomerPassword"
        );
        const result = await resetPasswordFunction({ oobCode, newPassword });
        if (result.data.success) {
          return { message: "Password reset successful!" };
        }
      } catch (err) {
        console.error("Error resetting password: ", err);
        return thunkAPI.rejectWithValue("Failed to reset password. Please try again.");
      }
    }
  );
  
  const adminSlice = createSlice({
    name: "admin",
    initialState: {
      customers: [],
      error: null,
      status: "idle",
      lastFetchTimestamp: null,
      passwordResetStatus: null,
    },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ... existing cases ...
      .addCase(editCustomer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.customers.findIndex(
          (customer) => customer.id === action.payload.customerId
        );
        if (index !== -1) {
          state.customers[index] = {
            ...state.customers[index],
            ...action.payload.updatedData,
          };
        }
        state.error = null;
      })
      .addCase(editCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customers = state.customers.filter(
          (customer) => customer.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(fetchCustomers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.customers !== null) {
          state.customers = action.payload.customers;
        }
        state.lastFetchTimestamp = action.payload.lastUpdated;
        state.error = null;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(resetCustomerPassword.pending, (state) => {
        state.passwordResetStatus = "loading";
      })
      .addCase(resetCustomerPassword.fulfilled, (state, action) => {
        state.passwordResetStatus = "succeeded";
        state.error = null;
      })
      .addCase(resetCustomerPassword.rejected, (state, action) => {
        state.passwordResetStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const selectAllCustomers = (state) => state.admin.customers;
export const selectAdminError = (state) => state.admin.error;
export const selectAdminStatus = (state) => state.admin.status;
export const selectPasswordResetStatus = (state) => state.admin.passwordResetStatus;
export const selectLastFetchTimestamp = (state) =>
  state.admin.lastFetchTimestamp;

export default adminSlice.reducer;
