// CompanyFrontPage.js
import React, { useState } from 'react';
import backgroundImage from './images/backgroundimage.jpg'; // Replace with actual path to background image

const CompanyFrontPage = () => {
  const [activeTab, setActiveTab] = useState('Services');

  const tabs = ['Services', 'About', 'Contact'];

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Background Image */}
      <div className="fixed inset-0 z-0">
        <img src={backgroundImage} alt="Futuristic Background" className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-white bg-opacity-10"></div> {/* Make background visible */}
      </div>

      {/* Content */}
      <div className="relative z-10">
        {/* Header */}
        <header className="bg-gray-700 bg-opacity-80 p-6 shadow-lg">
          <div className="flex justify-between items-center container mx-auto">
            <h1 className="text-3xl font-bold text-white">Simplicity Dynamics</h1>
            <nav className="space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`text-lg font-semibold ${activeTab === tab ? 'text-teal-300' : 'text-white'} hover:text-teal-300`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>
        </header>

        {/* Main Content */}
        <main className="container mx-auto p-6">
          {/* Hero Section */}
          <section className="text-center py-16 bg-gray-700 bg-opacity-80 rounded-lg shadow-lg">
            <h2 className="text-4xl font-bold text-white">Manage Your Job Sites Effortlessly</h2>
            <p className="mt-4 text-lg text-gray-300">Job site management, storage solutions, and equipment tracking made simple with modern technology.</p>
            <button className="mt-6 px-6 py-3 bg-teal-500 hover:bg-teal-600 text-white rounded-lg">Get Started</button>
          </section>

          {/* Services Section */}
          <section id="services" className="py-16">
            <h3 className="text-3xl font-bold text-center text-white">Our Services</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
              <ServiceCard title="Job Site Management" description="Efficiently manage all your job sites in one platform." />
              <ServiceCard title="Storage Management" description="Streamline your storage with cutting-edge tools." />
              <ServiceCard title="Equipment Tracking" description="Keep track of your equipment in real-time." />
            </div>
          </section>

          {/* About Section */}
          <section id="about" className="py-16 bg-gray-700 bg-opacity-80 rounded-lg shadow-lg">
            <div className="text-center">
              <h3 className="text-3xl font-bold text-white">About Us</h3>
              <p className="mt-4 text-gray-300 max-w-3xl mx-auto">
                Simplicity Dynamics is a cutting-edge software development company specializing in job site management, storage solutions, and equipment tracking. Our tools ensure you stay organized and efficient.
              </p>
            </div>
          </section>

          {/* Contact Section */}
          <section id="contact" className="py-16 text-center">
            <h3 className="text-3xl font-bold text-white">Get In Touch</h3>
            <p className="mt-4 text-gray-300">Ready to simplify your job site management? Contact us today!</p>
            <div className="mt-6">
              <a href="mailto:info@simplicitydynamics.com" className="px-6 py-3 bg-teal-500 hover:bg-teal-600 text-white rounded-lg">Email Us</a>
            </div>
          </section>
        </main>

        {/* Footer */}
        <footer className="bg-gray-700 bg-opacity-80 text-white py-4">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 Simplicity Dynamics. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

const ServiceCard = ({ title, description }) => (
  <div className="bg-gray-800 bg-opacity-80 shadow-md rounded-lg p-6 text-center text-white">
    <h4 className="text-xl font-semibold">{title}</h4>
    <p className="mt-4 text-gray-300">{description}</p>
  </div>
);

export default CompanyFrontPage;
