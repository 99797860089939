import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetCustomerPassword, selectPasswordResetStatus, selectAdminError } from '../../redux/adminSlice';
import { verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../firebase.config';

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const passwordResetStatus = useSelector(selectPasswordResetStatus);
  const error = useSelector(selectAdminError);

  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode');

  useEffect(() => {
    const verifyCode = async () => {
      try {
        const email = await verifyPasswordResetCode(auth, oobCode);
        setEmail(email);
        setIsValid(true);
      } catch (error) {
        setIsValid(false);
      }
    };
    if (oobCode) {
      verifyCode();
    }
  }, [oobCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }
    dispatch(resetCustomerPassword({ oobCode, newPassword }));
  };

  useEffect(() => {
    if (passwordResetStatus === 'succeeded') {
      alert('Password reset successful! Redirecting to login...');
      setTimeout(() => navigate('/login'), 3000);
    }
  }, [passwordResetStatus, navigate]);

  if (!isValid) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-200 via-gray-400 to-gray-600 flex items-center justify-center">
        <div className="bg-gray-700 bg-opacity-80 rounded-lg shadow-lg p-6 w-full max-w-md">
          <h2 className="text-2xl font-bold text-white mb-4">Password Reset</h2>
          <p className="text-red-300">Invalid or expired reset link. Please try again.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-200 via-gray-400 to-gray-600 flex items-center justify-center">
      <div className="bg-gray-700 bg-opacity-80 rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold text-white mb-4">Reset Your Password</h2>
        <p className="text-white mb-4">Resetting password for: {email}</p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
            className="w-full p-2 rounded"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm New Password"
            className="w-full p-2 rounded"
            required
          />
          <button type="submit" className="w-full bg-teal-500 hover:bg-teal-600 text-white p-2 rounded" disabled={passwordResetStatus === 'loading'}>
            {passwordResetStatus === 'loading' ? 'Resetting...' : 'Reset Password'}
          </button>
        </form>
        {error && <p className="mt-4 text-center text-red-300">{error}</p>}
        {passwordResetStatus === 'succeeded' && <p className="mt-4 text-center text-teal-300">Password reset successful! Redirecting to login...</p>}
      </div>
    </div>
  );
};

export default PasswordReset;