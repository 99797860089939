import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase.config';
import { createUser, selectAdminError, selectAdminStatus, selectAllCustomers, fetchCustomers } from '../redux/adminSlice';
import { useCustomerUtils, CustomerTable } from './cutsomerUtils/customerUtils';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('Dashboard');
  const [newUser, setNewUser] = useState({ email: '', password: '', role: 'user' });
  const [newCustomer, setNewCustomer] = useState({ name: '', email: '' });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(selectAdminError);
  const status = useSelector(selectAdminStatus);
  const customers = useSelector(selectAllCustomers); 

  const { 
    handleAddCustomer, 
    handleEditCustomer, 
    handleDeleteCustomer, 
    handleSendPasswordReset, 
  } = useCustomerUtils();

  const tabs = ['Dashboard', 'Customers', 'User Management', 'Firebase Management', 'Settings'];

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const handleAddUser = async (e) => {
    e.preventDefault();
    dispatch(createUser(newUser));
    setNewUser({ email: '', password: '', role: 'user' });
  };

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    await handleAddCustomer(newCustomer);
    setNewCustomer({ name: '', email: '' });
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      console.error("Error signing out: ", err);
    }
  };

  if (status === 'loading') {
    return <div className="text-white text-center">Loading dashboard...</div>;
  }


  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-200 via-gray-400 to-gray-600">
      <div className="relative z-10">
        <header className="bg-gray-700 bg-opacity-90 p-6 shadow-lg">
          <div className="flex justify-between items-center container mx-auto">
            <h1 className="text-3xl font-bold text-white">Simplicity Dynamics Admin</h1>
            <nav className="space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`text-lg font-semibold ${activeTab === tab ? 'text-teal-300' : 'text-white'} hover:text-teal-300`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
              <button
                className="text-lg font-semibold text-white hover:text-red-300"
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            </nav>
          </div>
        </header>

        <main className="container mx-auto p-6">
          {error && <div className="bg-red-500 text-white p-3 rounded mb-4">{error}</div>}
          
          {activeTab === 'Dashboard' && (
            <section className="bg-gray-700 bg-opacity-80 rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-white mb-4">Dashboard Overview</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <StatCard title="Total Customers" value={customers.length} />
                <StatCard title="Active Projects" value="0" />
                <StatCard title="Total Revenue" value="$0" />
              </div>
            </section>
          )}

          {activeTab === 'Customers' && (
      <section className="bg-gray-700 bg-opacity-80 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-white mb-4">Manage Customers</h2>
        <form onSubmit={handleCustomerSubmit} className="mb-6">
              <input
                type="text"
                placeholder="Customer Name"
                value={newCustomer.name}
                onChange={(e) => setNewCustomer({ ...newCustomer, name: e.target.value })}
                className="p-2 rounded mr-2"
              />
              <input
                type="email"
                placeholder="Customer Email"
                value={newCustomer.email}
                onChange={(e) => setNewCustomer({ ...newCustomer, email: e.target.value })}
                className="p-2 rounded mr-2"
              />
              <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white p-2 rounded">
                Add Customer
              </button>
              </form>
        <CustomerTable 
          customers={customers}
          onSendPasswordReset={handleSendPasswordReset}
          onEditCustomer={handleEditCustomer}
          onDeleteCustomer={handleDeleteCustomer}
        />
      </section>
        )}

        {activeTab === 'User Management' && (
          <section className="bg-gray-700 bg-opacity-80 rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-bold text-white mb-4">User Management</h2>
            <form onSubmit={handleAddUser} className="mb-6">
              <input
                type="email"
                placeholder="User Email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                className="p-2 rounded mr-2"
                autoComplete="username"
              />
              <input
                type="password"
                placeholder="User Password"
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                className="p-2 rounded mr-2"
                autoComplete="new-password"
              />
              <select
                value={newUser.role}
                onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                className="p-2 rounded mr-2"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
              <button type="submit" className="bg-teal-500 hover:bg-teal-600 text-white p-2 rounded">
                Add User
              </button>
            </form>
              {/* Add user list here if needed */}
            </section>
          )}

          {activeTab === 'Firebase Management' && (
            <section className="bg-gray-700 bg-opacity-80 rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-white mb-4">Firebase Management</h2>
              <p className="text-white">Here you can manage your Firebase settings and data.</p>
              {/* Add Firebase management tools here */}
            </section>
          )}

          {activeTab === 'Settings' && (
            <section className="bg-gray-700 bg-opacity-80 rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-bold text-white mb-4">Admin Settings</h2>
              <p className="text-white">Configure your admin dashboard settings here.</p>
              {/* Add settings options here */}
            </section>
          )}
        </main>

        <footer className="bg-gray-700 bg-opacity-80 text-white py-4">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 Simplicity Dynamics Admin. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

const StatCard = ({ title, value }) => (
  <div className="bg-gray-800 bg-opacity-80 p-4 rounded-lg">
    <h3 className="text-xl font-semibold text-white">{title}</h3>
    <p className="text-2xl font-bold text-teal-300">{value}</p>
  </div>
);

export default AdminDashboard;