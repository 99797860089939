import React, { useState } from 'react';
   import { createUserWithEmailAndPassword } from 'firebase/auth';
   import { setDoc, doc } from 'firebase/firestore';
   import { auth, db } from '../../firebase.config';
   import { useNavigate } from 'react-router-dom';

   const Register = () => {
     const [email, setEmail] = useState('');
     const [password, setPassword] = useState('');
     const [error, setError] = useState(null);
     const navigate = useNavigate();

     const handleRegister = async (e) => {
       e.preventDefault();
       setError(null);
       try {
         // Create user in Firebase Authentication
         const userCredential = await createUserWithEmailAndPassword(auth, email, password);
         const user = userCredential.user;

         // Add user to Firestore with pending admin role
         await setDoc(doc(db, 'users', user.uid), {
           email: user.email,
           role: 'pending_admin'
         });

         console.log('User registered successfully:', user.uid);
         navigate('/admin');
       } catch (error) {
         console.error('Registration error:', error);
         setError(error.message);
       }
     };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-200">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">Register Admin User</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <button type="submit" className="w-full bg-green-500 text-white p-2 rounded hover:bg-green-600">
            Register Admin
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;